/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import Img from 'gatsby-image'

// Styles
import 'lightbox-react/style.css'

// components
import { PrevArrow, NextArrow } from 'components/Common/Arrows'

// Third Party
import Slider from 'react-slick'
import styled from 'styled-components'
import { size } from 'lodash'
import Lightbox from 'lightbox-react'

const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: hidden;
    margin: 0 -10px;
  }

  .slick-slide {
    margin: 0 10px;
  }

  .slick-track {
    display: flex;
  }
`

const StyledImg = styled(Img)`
  cursor: pointer;
  transition: transform .5s ease;

  :hover {
    transform: scale(1.2);
  }
`

const CampaignImageSliderWrapper = styled.div``

const ImgContainer = styled.div`
  overflow: hidden;
  max-width: 600px;
  max-height: 400px;
`

const CampaignImageSlider = ({ data, className }) => {
  const [mediaData, setMediaData] = useState({
    photoIndex: 0,
    isVisible: false
  })

  return (
    <CampaignImageSliderWrapper className={`${className ? `${className}` : ``}`}>
      <StyledSlider
        autoplay
        autoplaySpeed={4000}
        infinite
        speed={500}
        slidesToShow={data.length >= 3 ? 3 : data.length}
        slidesToScroll={1}
        prevArrow={<PrevArrow middle />}
        nextArrow={<NextArrow middle />}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: data.length >= 2 ? 2 : data.length
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1
            }
          }
        ]}
      >
        {data.map((image, index) => (
          <ImgContainer role="button" tabIndex="0" onKeyPress={() => {}} onClick={() => setMediaData({ photoIndex: index, isVisible: true})}>
            <StyledImg key={index} fluid={image.image.localFile.childImageSharp.fluid} alt="" />
          </ImgContainer>
        ))}
      </StyledSlider>

      {mediaData.isVisible && (
        <Lightbox
          mainSrc={data[mediaData.photoIndex].image.localFile.childImageSharp.fluid.src}
          nextSrc={data[(mediaData.photoIndex + 1) % size(data)].image.localFile.childImageSharp.fluid.src}
          prevSrc={
            data[(mediaData.photoIndex + size(data) - 1) % size(data)]
          }
          onCloseRequest={() =>
            setMediaData({ ...mediaData, ...{ isVisible: false } })}
          onMovePrevRequest={() =>
            setMediaData({
              ...mediaData,
              ...{
                photoIndex:
                  (mediaData.photoIndex + size(data) - 1) % size(data),
              },
            })}
          onMoveNextRequest={() =>
            setMediaData({
              ...mediaData,
              ...{ photoIndex: (mediaData.photoIndex + 1) % size(data) },
            })}
        />
        )}
    </CampaignImageSliderWrapper>
  )
}

export default CampaignImageSlider