/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import BackgroundImage from 'gatsby-background-image'

// CSS
import 'styles/CampagneDetail.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CampaignImageSlider from 'components/Campaign/CampaignImageSlider'
import CampaignGridItem from 'components/Campaign/CampaignGridItem'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const SecondHeader = styled.h2`
  margin: 0;
`

const Paragraph = styled.p``

const StyledCustomLink = styled(CustomLink)`
  ${Paragraph} {
    height: 50px;
    width: 300px;
    border-radius: 5px;
    transition: 0.25s ease;
  }

  :hover {
    ${Paragraph} {
      transform: scale(1.05);
      background-color: #2b2652;
    }
  }
`

const Detail = styled.div`
  .breadcrumb {
    a, span {
      color: #ffffff !important;
    }
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 525px;
  opacity: 0.55;
  mix-blend-mode: multiply;
  background-color: #001843;
`

const DetailBlock = styled.div`
  top: -209px;
`

const ContentContainer = styled.div`
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
`

const Introduction = styled.div`
  p {
    line-height: 24px;
    color: #2b2652;
    font-size: 16px;
    padding-left: 1rem;
  }
`

const Story = styled.div`
  p {
    line-height: 24px;
    color: #9b9b9b;
    font-size: 16px;
    padding-left: 1rem;
  }
`

const TargetDescription = styled.div`
  p {
    font-size: 16px;
    color: #ffffff;
  }
`

const TargetContainer = styled.div`
  z-index: -1;
  top: -290px;
`

const Title = styled.section`
  div {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`

const StyledImage = styled.img`
  width: 200px;
`

const TargetWrapper = styled.div`
  width: 100%;

  ${TargetContainer} {
    width: 100%;
  }
`

const CampagneDetail = ({ data, pageContext }) => {
  const {
    wordpressWpCampagnes: {
      title,
      wpml_current_locale: language,
      acf: {
        information,
        content,
        targets_label: targetsLabel,
        targets_group: targetsGroup,
        story_images: storyImages
      },
    },
    others: {
      edges: otherCampaigns
    }
  } = data

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(87deg, rgba(0,24,67,0.55) 0%, rgba(0,24,67,0.55) 100%)`,
    ].reverse()
    return gradient
  }

  const breadcrumbIndex = pageContext.breadcrumb.crumbs.indexOf(pageContext.breadcrumb.crumbs.find((o) => o.crumbLabel === 'campagnes' || o.crumbLabel === 'campaigns'))

  if (breadcrumbIndex !== -1) {
    pageContext.breadcrumb.crumbs[breadcrumbIndex] = language === 'nl_NL' ? { pathname: "/ons-werk", crumbLabel: "ons werk" } : { pathname: "/en/our-work", crumbLabel: "our work" }
  }

  return (
    <Layout extraFooterPiece>
      <SEO title={title} />
      <Detail className="campagne-detail">

        <StyledBackgroundImage fluid={addGradient(information.image.localFile.childImageSharp.fluid)} alt="" />

        <DetailBlock className="position-relative">
          <Title className="title">
            <div className="container color-background-main py-2 pl-4">
              <Breadcrumb crumbSeparator={' > '} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={parse(title)} />
            </div>
          </Title>

          <section className="campaign-detail-content">
            <ContentContainer className="container py-5 color-background-light">
              <div className="row">
                <div className="col-12 col-lg-8 pr-lg-5">
                  <div className="row">
                    <div className="col-2 d-none d-lg-flex justify-content-center">
                      <img
                        className="campaign-detail-content-image"
                        alt=""
                        src={information.dienst.acf.icon.localFile.publicURL}
                      />
                    </div>
                    <div className="col-10">
                      <h3 className="font-size-ms font-weight-xl color-text-contrast">{parse(information.dienst.title)}</h3>
                      <h2 className="font-size-xml font-weight-xl">{parse(title)}</h2>
                    </div>
                    <div className="col-12 pb-3">
                      <h2 className="m-0 font-size-m font-weight-xl pl-3 pt-4">{information.sub_title}</h2>
                    </div>
                    <InformationContainer label={information.dienst_label} name={information.dienst.title} />
                    <InformationContainer
                      className="pb-3"
                      label={information.medium_label}
                      name={information.medium.title}
                    />
                    <InformationContainer label={information.branche_label} name={information.branche} />
                    <InformationContainer
                      className="pb-5"
                      label={information.brand.label}
                      name={information.brand.name}
                    />
                    {parse(content.intro)[0].props.children !== "empty" ? (
                      <Introduction className="col-11 pl-3 pb-3 ">
                        {parse(content.intro)}
                      </Introduction>
                    ) : (
                      <div />
                    )}
                    <Story className="col-11 pl-3">
                      {parse(content.story)}
                    </Story>
                  </div>
                </div>
                <div className="col-12 col-lg-4 pl-lg-5 d-flex d-lg-block justify-content-lg-start justify-content-center py-lg-0 py-4">
                  <StyledImage alt="" src={information.brand.image.localFile.publicURL} />
                </div>
              </div>
              {title !== 'Procter &#038; Gamble' && (
                <div className="px-0 px-lg-5">
                  <CampaignImageSlider data={storyImages} className="pt-5" />
                </div>
              )}
            </ContentContainer>
          </section>
        </DetailBlock>
        <TargetWrapper className="position-relative">
          <TargetContainer className="position-absolute color-background-main">
            <div className="container py-5">
              {parse(targetsGroup.description)[0].props.children !== "empty" ? (
                <TargetDescription className="pt-5 px-5">
                  <h2 className="font-weight-xl color-text-light font-size-xl">{targetsLabel}</h2>
                  {parse(targetsGroup.description)}
                </TargetDescription>
              ) : (
                <div className="py-5" />
              )}

            </div>
          </TargetContainer>
        </TargetWrapper>
        <div className="container py-5">
          <SecondHeader className="font-size-xl pb-5">{language === 'nl_NL' ? `Ontdek meer campagnes` : `Discover more campaigns`}</SecondHeader>
          <div className="row">
            {otherCampaigns.map((camp, index) => (
              <CampaignGridItem className="col-lg-4 mb-5 mb-lg-0" campaign={camp.node} key={index} />
            ))}
          </div>
          <StyledCustomLink className="pt-lg-5 pb-lg-0 pb-4 d-flex justify-content-center" to={language === 'nl_NL' ? `/ons-werk` : `/en/our-work`}>
            <Paragraph className="color-text-light font-weight-m font-size-ms d-flex justify-content-center align-items-center color-background-contrast">{language === 'nl_NL' ? `Naar het campagne overzicht` : `To the campaigns overview`}</Paragraph>
          </StyledCustomLink>
        </div>
        <div className="empty-space-100 mb-5" />
      </Detail>
    </Layout>

  )
}

export default CampagneDetail

export const pageQuery = graphql`
	query CampagneDetailQuery($wordpress_id: Int!, $language: String!) {
		wordpressWpCampagnes(wordpress_id: { eq: $wordpress_id }) {
			id
			wordpress_id
			title
			slug
      wpml_current_locale
			acf {
				information {
					sub_title
					branche_label
					branche
					medium_label
					medium {
						slug
						title
					}
					dienst_label
					dienst {
						title
						slug
						acf {
							icon {
                localFile {
                  publicURL
                }
							}
						}
					}
					brand {
						label
						name
						image {
              localFile {
                publicURL
              }
						}
					}
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				content {
					intro
					story
				}
        targets_label
				targets_group {
					description
				}
        story_images {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp
								}
              }
            }
          }
        }
			}
		}
    others: allWordpressWpCampagnes(filter: {wpml_current_locale: {eq: $language}, wordpress_id: {ne: $wordpress_id}}, limit: 3) {
      edges {
        node {
          wordpress_id
          path
          acf {
            content {
              intro
            }
            information {
              sub_title
              brand {
                name
              }
              dienst {
                acf {
                  icon {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          title
          slug
        }
      }
    }
	}
`

const InformationContainerDiv = styled.div`
  p {
    line-height: 19px;
  }
`

const InformationContainer = ({ className, label, name }) => (
  <InformationContainerDiv className={`w-100 d-flex justify-content-start pl-3 ${className ? `${className}` : ``}`}>
    <div className="col-2">
      <p className="m-0 font-weight-xl font-size-sm color-text-contrast">{parse(label)}</p>
    </div>
    <div className="col-10">
      <p className="m-0 font-size-sm color-text-main">{parse(name)}</p>
    </div>
  </InformationContainerDiv>
)